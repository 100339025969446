import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import '../common/index.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Home extends Component {
constructor(props) {
super(props);
if(!localStorage.getItem('userData')) {
this.props.history.push("login");
}	
this.logout = this.logout.bind(this);
}
componentDidMount() {

setTimeout(function() {
document.querySelector(".loader-wrapper").style = "display: none";
}, 2000);
if (localStorage.getItem('userData')){
var storedArray = JSON.parse(localStorage.getItem("userData"));
	document.getElementById("name").innerHTML = storedArray['data']['pharmacy_name'];
	document.getElementById("mobile").innerHTML = storedArray['data']['pharmacy_phone'];
	var img = storedArray['data']['image'];
	document.getElementById('profile_img').style.backgroundImage = "url('"+img+"')";
}
}
logout(){
localStorage.setItem("userData",'');
localStorage.clear();
this.props.history.push("login");
}
render() {
return (
<div>
   <Helmet>
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css"/>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
   </Helmet>
   <div className="_3mMtt dashboard m-plan my_profile">
      <section className="renew-tab tab-product mt-20 purchase-tab home-left-mar">
         <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/`}>
                     <img className="logo-img" src={`${process.env.PUBLIC_URL}/assets/images/logo_icon.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                     Classic Xtra Pharmacy
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
                    <div id="from_name"></div>
            </form>
         </div>
         <div className="row pharmacy">
            <div className="col-sm-12 col-lg-12 tab-content nav-material">
               <ul class="nav nav-tabs nav-material">
                  <li class="nav-item "><Link to={`${process.env.PUBLIC_URL}/`}>Order's Chat</Link></li>
                  <li class="nav-item active"><a data-toggle="pill" href="#myprofile">My Profile</a></li>
               </ul>
               <div class="tab-content">
                  <div id="myprofile" class="tab-pane fade in active">
                     <div className="row">
                        <div className="col-sm-12 col-lg-12">
                           <div class="_3AFC5 home-lft-mar">
                              <div class=" margin-pd">
                                <div class="_2czoF">
                                    <div className="res-details-info">
                                       <div className="order-image">
                                          <div className="bgimg" id="profile_img"></div>
										</div>
                                    <div class="_2_-Em" id="name"></div>
									</div>
									<div class="_1KevM"><span id="mobile"></span> </div>
								</div>
                              <div>
                                 <div class="NeDfT">
                                    <div class="_3tVyU">
                                       <div class="_39p-f _3lD0G">
                                          <div class="_1Xqx7">
                                            <div class="_32sRE">
                                                <Link to={`${process.env.PUBLIC_URL}/manage_profile`}>
                                                <div class="_2AdD- _2jjDQ">
                                                   <div class="_2gQbw">
                                                      <div class="Zo88I">
                                                         <span class="_33X77"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/home.svg`} alt="" /></span>
                                                         <div>Edit Profile</div>
														 <input type="hidden" id="last_id"/>
                                                      </div>
                                                   </div>
                                                   <span class="icon-downArrow _1gsD2"></span>
                                                </div>
                                                </Link>
                                             </div>
                                             <div class="_32sRE">
                                                <Link to={`${process.env.PUBLIC_URL}/manage_delivery`}>
                                                <div class="_2AdD- _2jjDQ">
                                                   <div class="_2gQbw">
                                                      <div class="Zo88I">
                                                         <span class="_33X77"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/truck.svg`} alt="" /></span>
                                                         <div>Manage Delivery & Discount</div>
                                                      </div>
                                                   </div>
                                                   <span class="icon-downArrow _1gsD2"></span>
                                                </div>
                                                </Link>
                                             </div>
											 <div class="_32sRE">
                                                <Link to={`${process.env.PUBLIC_URL}/manage_password`}>
                                                <div class="_2AdD- _2jjDQ">
                                                   <div class="_2gQbw">
                                                      <div class="Zo88I">
                                                         <span class="_33X77"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/lock.svg`} alt="" /></span>
                                                         <div>Change Password</div>
                                                      </div>
                                                   </div>
                                                   <span class="icon-downArrow _1gsD2"></span>
                                                </div>
                                                </Link>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <section class="_2N4la">
                              <div>
                                 <div class="_1q4Zi" onClick={this.logout}>
                                    <div>Logout</div>
                                    <span class="Zo88I"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/logout.svg`} alt="" /></span>
                                 </div>
                              </div>
                           </section>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
   </div>
   </section>
</div>
{/*collection banner end*/}
</div>
)
}
}
export default Home;