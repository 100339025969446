import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {getListData} from '../../services/index';

import {Link} from 'react-router-dom';
import '../common/index.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import $ from 'jquery';

class Delivery extends Component {
	constructor(props) {
        super(props);
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			delivery_time: storedArray['data']['delivery_time'],
			discount: storedArray['data']['discount'],
			min_order: storedArray['data']['min_order'],
			pharmacy_id: storedArray['data']['pharmacy_id'],
			is_active: storedArray['data']['is_active'],
			is_delivery: storedArray['data']['is_delivery'],
			radius: storedArray['data']['radius'],
		};
		if(!localStorage.getItem('userData')) {
		this.props.history.push("login");
		}
		this.addadress = this.addadress.bind(this);
		this.logout = this.logout.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		
		var da = storedArray['data']['from_delivery'] ;
		
			var from_delivery = storedArray['data']['from_delivery'];
			var newStr = from_delivery.replace(' ', ',');
			var separatedArray = newStr.split(','); 
			this.state.from_delivery_time = separatedArray[0];
			this.state.from_delivery_status = separatedArray[1];
			
			var to_delivery = storedArray['data']['to_delivery'];
			var newStr1 = to_delivery.replace(' ', ',');
			var separatedArray1 = newStr1.split(','); 
			this.state.to_delivery_time = separatedArray1[0];
			this.state.to_delivery_status = separatedArray1[1];
			
			this.state.is_active = storedArray['data']['is_active'];
		
		
		
	}
	
	
	addadress() {
		if(this.state.delivery_time || this.state.discount || this.state.min_order){
			
			getListData('pharmacy/update_pharmacy_delivery',this.state).then((result) => {
			if(result.status==400){
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> 1 Please insert all fields</div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
			}
			
			let responseJson = result;
				if(responseJson.data){					
					localStorage.setItem('userData',JSON.stringify(responseJson));
					 this.setState({redirectToReferrer: true});
				}
			});
		}
		if(this.state.delivery_time=='' || this.state.discount=='' || this.state.min_order=='' ){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> 0 Please insert all fields </div>';
		}
		else{
			document.getElementById('alert').innerHTML='';
		}
	}
	
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	
	handleChange = (event) => {
		var name = event.target.name
        this.setState({
            [event.target.name] : event.target.value
        });
    }
	
    componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: none";
		
	$('.float-label-1 .form-control-1[placeholder]').each(function () {
		if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');}
	});
	$('.float-label-1 .form-control-1[value]').each(function () {
		if ($(this).val.length > 0) { $(this).addClass('active');}
	});
	$('.float-label-1 .form-control-1').on('blur', function () {
		if ($(this).val().length > 0) { $(this).addClass('active');} else {
			if ($(this)[0].hasAttribute('placeholder') === true) {
				if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');} 
				else { $(this).removeClass('active');}
			} else { $(this).removeClass('active');}
		}
	});
	if (localStorage.getItem('userData')){
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	
	this.state.is_active = storedArray['data']['is_active'];
	
	document.getElementById("discount").value = storedArray['data']['discount'];
	document.getElementById("min_order").value = storedArray['data']['min_order'];
	document.getElementById("radius").value = storedArray['data']['radius'];
	
	document.getElementById("discount").classList.add("active");
	document.getElementById("min_order").classList.add("active");
	document.getElementById("radius").classList.add("active");
	
	
	
	
	
	
	}
	}
	
	
	logout(){
	localStorage.setItem("userData",'');
	localStorage.clear();
	this.props.history.push("login");
	}
	render() {
	if (this.state.redirectToReferrer ){
			return (<Redirect to={'/my_profile'}/>)
		}
		return (
			<div>
                
				
                 
                {/*Home Section End*/}

                {/*collection banner*/}
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						<form>
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/my_profile`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title ">
								Manage Delivery
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						</form>
					 </div>
				    
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    <div className="res-details-div" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="details-dish-con">
								<div className="col-md-12">
									<div className="wrap-login200">
										<form className="login100-form validate-form">
											
											
											<div className="col-md-12 no-pad">
												<label className="tag color">Delivery Time</label>
												<div class="select">
												  <select name="delivery_time" id="delivery_time" value={this.state.delivery_time} onChange={this.handleChange}>
													<option disabled>Choose an option</option>
													<option value="30-45 Minutes" selected={'30-45 Minutes' === this.state.delivery_time }>30-45 Minutes</option>
													<option value="30-60 Minutes" selected={'30-60 Minutes' === this.state.delivery_time}>30-60 Minutes</option>
													<option value="45-60 Minutes" selected={'45-60 Minutes' === this.state.delivery_time}
													>45-60 Minutes</option>
													<option value="1 Hour" selected={'1 Hour' === this.state.delivery_time}>1 Hour</option>
													<option value="1-2 Hour" selected={'1-2 Hour' === this.state.delivery_time}>1-2 Hour</option>
												  </select>
												</div>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter address">
												<input type="number" className="form-control-1" name="min_order" id="min_order" autoComplete="off" onChange={this.onChange}/>
												<label className="color"> Minimum Order</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter Discount %">
												<input type="number" className="form-control-1" name="discount" id="discount" autoComplete="off" onChange={this.onChange}/>
												<label className="color"> Discount %</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter Discount %">
												<input type="number" className="form-control-1" name="radius" id="radius" autoComplete="off" onChange={this.onChange}/>
												<label className="color">Delivery Radius Area (km)</label>
												</div>
											<div className="form-group-1 col-md-12 no-pad">
												<label className="tag color block">Start Delivery Time</label>
												<div class="select del-time">
												  <select name="from_delivery_time" id="from_delivery_time" value={this.state.from_delivery_time} onChange={this.handleChange}>
													<option disabled>Choose an option</option>
													<option value="1" selected={'1' === this.state.from_delivery_time}>1</option>
													<option value="2" selected={'2' === this.state.from_delivery_time}>2</option>
													<option value="3" selected={'3' === this.state.from_delivery_time}>3</option>
													<option value="4" selected={'4' === this.state.from_delivery_time}>4</option>
													<option value="5" selected={'5' === this.state.from_delivery_time}>5</option>
													<option value="6" selected={'6' === this.state.from_delivery_time}>6</option>
													<option value="7" selected={'7' === this.state.from_delivery_time}>7</option>
													<option value="8" selected={'8' === this.state.from_delivery_time}>8</option>
													<option value="9" selected={'9' === this.state.from_delivery_time}>9</option>
													<option value="10" selected={'10' === this.state.from_delivery_time}>10</option>
													<option value="11" selected={'11' === this.state.from_delivery_time}>11</option>
													<option value="12" selected={'12' === this.state.from_delivery_time}>12</option>
												  </select>
												</div>
												<div class="select del-time">
												   <select name="from_delivery_status" id="from_delivery_status" value={this.state.from_delivery_status}  onChange={this.handleChange}>
													<option value="AM" selected={'AM' === this.state.from_delivery_status}>AM</option>
													<option value="PM" selected={'PM' === this.state.from_delivery_status}>PM</option>
													
												  </select>
												</div>
												
												<label className="tag color block">End Delivery Time</label>
												<div class="select del-time">
												  <select name="to_delivery_time" id="to_delivery_time" value={this.state.to_delivery_time} onChange={this.handleChange}>
													<option disabled>Choose an option</option>
													<option value="1" selected={'1' === this.state.to_delivery_time}>1</option>
													<option value="2" selected={'2' === this.state.to_delivery_time}>2</option>
													<option value="3" selected={'3' === this.state.to_delivery_time}>3</option>
													<option value="4" selected={'4' === this.state.to_delivery_time}>4</option>
													<option value="5" selected={'5' === this.state.to_delivery_time}>5</option>
													<option value="6" selected={'6' === this.state.to_delivery_time}>6</option>
													<option value="7" selected={'7' === this.state.to_delivery_time}>7</option>
													<option value="8" selected={'8' === this.state.to_delivery_time}>8</option>
													<option value="9" selected={'9' === this.state.to_delivery_time}>9</option>
													<option value="10" selected={'10' === this.state.to_delivery_time}>10</option>
													<option value="11" selected={'11' === this.state.to_delivery_time}>11</option>
													<option value="12" selected={'12' === this.state.to_delivery_time}>12</option>
												  </select>
												</div>
												<div class="select del-time">
												   <select name="to_delivery_status" id="to_delivery_status" value={this.state.to_delivery_status} onChange={this.handleChange}>
													<option value="AM" selected={'AM' === this.state.to_delivery_status}>AM</option>
													<option value="PM" selected={'PM' === this.state.to_delivery_status}>PM</option>
													
												  </select>
												</div>
												<label className="tag color block">Delivery Status</label>
												<div class="select del-time">
												   <select name="is_delivery" id="is_delivery" value={this.state.is_delivery} onChange={this.handleChange}>
													<option value="1" selected={'1' === this.state.is_delivery}>Delivery Open</option>
													<option value="0" selected={'0' === this.state.is_delivery}>Delivery Closed</option>
													
												  </select>
												</div>
												
												<div class="select del-time">
												   <select name="is_active" id="is_active" value={this.state.is_active} onChange={this.handleChange}>
													<option value='1' selected={'1' === this.state.is_active}>Medical Open</option>
													<option value='0' selected={'0' === this.state.is_active}>Medical Closed</option>
													
												  </select>
												</div>
												
												
											</div>
											<div id="alert" className="m-b-10"></div>
											<div className="container-login100-form-btn">				
												<input type="button"  className="login100-form-btn" value="Save" onClick={this.addadress}/>
											</div>	
											
											
										</form>
									</div>
								</div>		
							</div>
						</div>
					</div>
                </section>
				
				
				 
				


 
			</div>
			
			)

	}
}

export default Delivery;
