import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
class Search extends Component {
componentDidMount() {
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }
    constructor (props) {
        super (props)
		this.state = {		
		}    		
    }
	
	
    render (){
	   
        return (
            <div>
                
                <section className="section-b-space margin-pd">
					<div className="coming-soon">
						<img src={`${process.env.PUBLIC_URL}/assets/images/coming-soon.png`} alt=""/>
					</div>
                </section>
            </div>
        )
    }
}

export default Search