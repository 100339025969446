import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';

class HeaderOne extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
       
    }	
	render() {

		return (
			<div>
				<header id="sticky" className="sticky margin-pd pd-10">
					<Link to={`${process.env.PUBLIC_URL}/search`}>
					<img src={`${process.env.PUBLIC_URL}/assets/images/icon/logo_icon.png`} alt="" className="header-logo"/>
											<div className="_top_location">
												<div className="first_line">Jogeshwari <span className="location-arrow"><i className="fa fa-angle-down" aria-hidden="true"></i></span></div>
												<div className="second_line">Tapo Commercial Centre, Ram Mandir Rd, Mahatma Jyotib...</div>
											</div>
											</Link>
				</header>

			</div>
			)
	}
}

export default HeaderOne;