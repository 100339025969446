import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet'
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import '../../common/index.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {getListData} from '../../../services/index'



class Home extends Component {
constructor(props) {
super(props);
if(!localStorage.getItem('userData')) {
this.props.history.push("login");
}	
this.logout = this.logout.bind(this);
this.state = {
	ListData: [],
	chatCount:0
};


}
componentDidMount() {

setTimeout(function() {
document.querySelector(".loader-wrapper").style = "display: none";
}, 2000);
if (localStorage.getItem('userData')){
	var storedArray = JSON.parse(localStorage.getItem("userData"));
	var displayName = storedArray['data']['pharmacy_name'];
	var userId = storedArray['data']['pharmacy_id'];
	window.AskToken(userId);
	this.getData();
	setInterval(this.getData, 5000);
}

}
	getData = () => {
		if (localStorage.getItem('userData')){
		var storedArray = JSON.parse(localStorage.getItem("userData"));	
		var app = {
			to_id: storedArray['data']['pharmacy_id']
		}
		getListData('chat/chat_user_list', app).then((result) => {
			let responseJson = result;
			if (responseJson.data) {
				if (result.data.length > 0) {
					this.setState({
						ListData: []
					})
					this.setState({
						ListData: result['data'],
						chatCount:result['count']
					})
				}
				else{
					this.setState({
						//cartItems:result['count']
					})
				}
			}
			
		});
		}
    }


logout(){
localStorage.setItem("userData",'');
localStorage.clear();
this.props.history.push("login");
}




render() {
return (
<div>
   <Helmet>
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css"/>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js"></script>
   </Helmet>
   <div className="_3mMtt dashboard m-plan">
      <section className="renew-tab tab-product mt-20 purchase-tab home-left-mar">
         <div className="_2u0oK _1iY8Y border-blue">
            <form>
               <div className="_3TzfO page_head">
                  <div className="_2QN4q">
                     <Link to={`${process.env.PUBLIC_URL}/`}>
                     <img className="logo-img" src={`${process.env.PUBLIC_URL}/assets/images/logo_icon.png`} alt="" /></Link>
                  </div>
                  <div className="_1leRb page_title">
                     Classic Xtra Pharmacy
                  </div>
                  <div className="b5NJg"></div>
               </div>
               <input type="submit" hidden=""/>
			   <input type="hidden" id="last_id"/>
                    <div id="from_name"></div>
            </form>
         </div>
         <div className="row pharmacy mg_10">
            <div className="col-sm-12 col-lg-12 tab-content nav-material">
               <ul class="nav nav-tabs nav-material">
                  <li class="nav-item active"><a data-toggle="pill" href="#orderchat">Order's Chat</a></li>
                  <li class="nav-item "><Link to={`${process.env.PUBLIC_URL}/my_profile`}>My Profile</Link></li>
               </ul>
               <div class="tab-content">
                  <div id="orderchat" class="tab-pane fade in active">
                     
					 
					{(() => {
                           if (this.state.chatCount > 0) {
								return (
									<div className="">
									   {this.state.ListData.map((list) => (	
											<a href={`${process.env.PUBLIC_URL}/chat/${list.from_id}`}>
												<div class="sideBar-body">
													<div class="col-sm-1 col-xs-1 sideBar-avatar">
														<div class="avatar-icon">
															<img src="https://bootdey.com/img/Content/avatar/avatar1.png"/>
														</div>
													</div>
													<div class="col-sm-11 col-xs-11 sideBar-main">
														<div class="row">
															<div class="col-sm-9 col-xs-9 sideBar-name"> 
																<div class="name-meta">{list.name}</div>
																<div class="name-msg">{list.message}</div>
															</div>
															<div class="col-sm-3 col-xs-3 pull-right sideBar-time"> 
																<div class={`pull-right ${list.count>0?'chat_count':''}`}>{list.count}</div>
																<div class="time-meta pull-right">{list.created_at}</div>
															</div>
														</div>
													</div>
												</div>
											</a>
										) )}
									</div>
								)
                           }
						   else{
							   return (
								   <div>
									<img className="no-msg" src={`${process.env.PUBLIC_URL}/assets/images/no_msg.png`} alt="" />
								   </div>
								)
						    }
                           })()}
					 
						
                     
					  
                  </div>
            </div>
         </div>
   </div>
   </section>
</div>
{/*collection banner end*/}
</div>
)
}
}
export default Home;