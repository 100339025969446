import React, {Component} from 'react';
import { Link} from 'react-router-dom';

import {SlideUpDown} from "../../../services/script"
import LogoImage from "../headers/common/logo"

class FooterOne extends Component {

    componentDidMount(){
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function(elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }


    render () {

        return (
            <footer className="footer-light">
            <div className="_3JaG6 bottom-menu margin-pd">
				<div className="_2Rt_0">
					<div className="_29wpS">
					    <Link to={`${process.env.PUBLIC_URL}/`} className="_1D7fu _2n_pK active">
							<span className="bottom-icon"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/home_active.png`} alt="" /></span>
							<span>Home</span>
						</Link>
					    <Link to={`${process.env.PUBLIC_URL}/account`} className="_1D7fu _2n_pK">
							<span className="bottom-icon"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/user.png`} alt="" /></span>
							<span>Account</span>
						</Link>
					    <Link to={`${process.env.PUBLIC_URL}/cart`} className="_1D7fu _2n_pK">
							<span className="bottom-icon"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/cart.png`} alt="" /></span>
							<span>Cart</span>
						</Link>
					    <Link to={`${process.env.PUBLIC_URL}/notification`} className="_1D7fu _2n_pK">
							<span className="bottom-icon"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/notification.png`} alt="" /></span>
							<span>Notification</span>
						</Link>
					</div>
				</div>
			</div>
            </footer>
        )
    }
}

export default FooterOne;