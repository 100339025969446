import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Link, NavLink} from 'react-router-dom';
import '../common/restaurant.scss';
import {getListData} from '../../services/index';

import $ from 'jquery';

class ManagePassword extends Component {
	constructor(props) {
        super(props);
		var storedArray = JSON.parse(localStorage.getItem("userData"));
		this.state = {
			password: '',
			con_password: '',
			pharmacy_id: storedArray['data']['pharmacy_id'],
			redirectToReferrer : false
			
			
		};
		if(!localStorage.getItem('userData')) {
		this.props.history.push("login");
		}	
		this.logout = this.logout.bind(this);
		this.addadress = this.addadress.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
	  this.setState({
		tag: event.target.value
	  });
	}
	
	addadress() {
		if(this.state.password || this.state.con_password){
			
			getListData('pharmacy/update_pharmacy_password',this.state).then((result) => {
			if(result.status==300){
			
				document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Password Does Not Match </div>';
			}
			else{
				document.getElementById('alert').innerHTML='';
			    this.setState({redirectToReferrer: true});
				
			}
			});
		}
		if(this.state.password=='' || this.state.con_password==''){
			document.getElementById('alert').innerHTML='<div class="alert alert-warning" role="alert"><i class="fa fa-info-circle" aria-hidden="true"></i> Please insert all fields </div>';
		}
		
	}

	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}

    componentDidMount() {
		document.querySelector(".loader-wrapper").style = "display: none";
		
	$('.float-label-1 .form-control-1[placeholder]').each(function () {
		if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');}
	});
	$('.float-label-1 .form-control-1[value]').each(function () {
		if ($(this).val.length > 0) { $(this).addClass('active');}
	});
	$('.float-label-1 .form-control-1').on('blur', function () {
		if ($(this).val().length > 0) { $(this).addClass('active');} else {
			if ($(this)[0].hasAttribute('placeholder') === true) {
				if ($(this).attr('placeholder').length > 0) { $(this).addClass('active');} 
				else { $(this).removeClass('active');}
			} else { $(this).removeClass('active');}
		}
	});
	
	}
	logout(){
	localStorage.setItem("userData",'');
	localStorage.clear();
	this.props.history.push("login");
	}

	render() {
	
	if (this.state.redirectToReferrer ){
			return (<Redirect to={'/my_profile'}/>)
		}
	
		return (
			<div>
                
				
                 
                {/*Home Section End*/}

                {/*collection banner*/}
                <section className="pb-0 margin-pd mt-45 restro">
					<div className="_2u0oK _1iY8Y">
						
						   <div className="_3TzfO page_head">
							  <div className="_2QN4q">
								 <Link to={`${process.env.PUBLIC_URL}/my_profile`}>
								 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/return_white.png`} alt="" /></Link>
							  </div>
							  <div className="_1leRb page_title">
								Manage Password
							  </div>
							  <div className="b5NJg"></div>
						   </div>
						   <input type="submit" hidden=""/>
						
					 </div>
				    
                </section>
				
				 {/*collection banner*/}
                <section className="pb-0 margin-pd restro details-pad-top-n">
				    <div className="res-details-div" role="button" tabIndex="0">
						<div className="res-details-info">
							<div className="details-dish-con">
								<div className="col-md-12">
									<div className="wrap-login200">
										<form className="login100-form validate-form">
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter password">
												<input type="text" className="form-control-1" name="password" id="password" autoComplete="off" onChange={this.onChange}/>
												<label className="color"> New Password</label>
											</div>
											<div className="form-group-1 float-label-1 validate-input" data-validate="Enter con_password">
												<input type="text" className="form-control-1" name="con_password" id="con_password" autoComplete="off" onChange={this.onChange}/>
												<label className="color"> Confirm Password</label>
											</div> 
											
											<div id="alert" className="m-b-10"></div>
											
											<div className="container-login100-form-btn">				
												<input type="button"  className="login100-form-btn" value="Save" onClick={this.addadress}/>
											</div>	
											
										</form>
									</div>
								</div>		
							</div>
						</div>
					</div>
                </section>
				
				
				 
				


 
			</div>
			
			)

	}
}

export default ManagePassword;
