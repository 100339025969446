import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';

// Layouts
import Home from './components/layouts/home/main';
import EditProfile from './components/pages/manage_profile';
import Chat from './components/pages/chat';
import ManageDelivery from './components/pages/manage_delivery';
import ManagePassword from './components/pages/manage_password';


import Notification from './components/pages/notification';
import Comingsoon from './components/pages/comingsoon';
import Login from './components/pages/login';
import MyProfile from './components/pages/my_profile';


import Layout from './components/app';
import PageNotFound from './components/pages/404';

class Root extends React.Component {

    render() {
        return(		
        	<Provider>
				<BrowserRouter basename={'/'} >
					<ScrollContext>
						<Switch>    
						    <Route path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
							<Layout>
								<Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
								<Route path={`${process.env.PUBLIC_URL}/my_profile`} component={MyProfile}/>
								<Route path={`${process.env.PUBLIC_URL}/chat/:id`} component={Chat}/>
								<Route path={`${process.env.PUBLIC_URL}/manage_profile`} component={EditProfile}/>
								<Route path={`${process.env.PUBLIC_URL}/manage_delivery`} component={ManageDelivery}/>
								
								<Route path={`${process.env.PUBLIC_URL}/notification`} component={Notification}/>
								
								<Route path={`${process.env.PUBLIC_URL}/manage_password`} component={ManagePassword}/>
								
								<Route path={`${process.env.PUBLIC_URL}/comingsoon`} component={Comingsoon}/>
								
								

							</Layout>
							<Route exact component={PageNotFound} />
						</Switch>	
					</ScrollContext>
				</BrowserRouter>
			</Provider>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
