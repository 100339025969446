import React, {Component} from 'react';
import Slider from 'react-slick';

import Breadcrumb from "../common/breadcrumb";
import {Slider2, Team4} from "../../services/script"

class Notification extends Component {
componentDidMount() {
		setTimeout(function() {
            document.querySelector(".loader-wrapper").style = "display: none";
        }, 2000);
    }
    constructor (props) {
        super (props)

    }

    render (){
        return (
            <div>
			<style>{'body { background-color: white !important; }'}</style>
   <div class="_1bhn9 margin-pd">
      <div class="_2czoF">
	  
		 <div class="_1KevM empty-cart">			 
		    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/notification.png`} alt="" />
		 </div>    
         <div class="_1KevM empty-cart">			 
			<div>No Notification Found!</div>
		 </div>
	  
   </div>
</div>

            </div>
        )
    }
}

export default Notification